// @flow
import styled, { css } from "styled-components";
import { media, mediumHeading, largeParagraph } from "@nested/brand";

const wrapperStyle = css`
  background: ${({ theme }) => theme.palette.yellow20};
  padding: 40px 20px;
  ${media.tablet`
    padding: 80px 20px;
  `}
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  ${media.tablet`
    width: 150px;
    height: 150px;
  `}
`;

const Header = styled.h1`
  ${mediumHeading}
  margin: 0 0 40px;
  ${media.desktop`
    max-width: 1140px;
    margin: 0 auto 40px;
  `}
`;

const cardsStyle = css`
  ${media.tablet`
    text-align: -webkit-center;
  `}
`;

const Cards = styled.div`
  ${media.tablet`
    display: flex;
    flex-wrap: wrap;
    max-width: 890px;
    justify-content: center;
    gap: 40px;
  `}
  ${media.maxDesktop`
    max-width: 1140px;
    margin: auto;
  `}
`;

const valueCardStyle = css`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  ${media.tablet`
    display: block;
    max-width: 196px;
    flex: 25%;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  `}
  ${media.desktop`
    display: inline-block;
    text-align: left;
  `}
`;

const textStyle = css`
  display: block;
  padding-left: 20px;
  ${media.tablet`
    display: grid;
    padding: 30px 0 0;
  `}
`;

const Value = styled.p`
  ${largeParagraph}
  font-weight: 500;
  margin: 0;
  ${media.tablet`
    height: 66px;
  `}
`;

const Description = styled.p`
  ${largeParagraph}
  margin: 10px 0 0 0;
`;

type ValueCardProps = {
  image: PrismicImage,
  value: string,
  description: string,
};

type ValuesProps = {
  repeat?: ValueCardProps[],
};

const ValueCard = ({ image, value, description }: ValueCardProps) => (
  <div css={valueCardStyle}>
    <Image src={image.url} alt={`${value} illustration`} />
    <div css={textStyle}>
      <Value>{value}</Value>
      <Description>{description}</Description>
    </div>
  </div>
);

export const Values = ({ repeat }: ValuesProps) => (
  <div css={wrapperStyle}>
    <Header>Our values</Header>
    {repeat && (
      <div css={cardsStyle}>
        <Cards>
          {repeat.map(({ image, value, description }) => (
            <ValueCard image={image} value={value} description={description} />
          ))}
        </Cards>
      </div>
    )}
  </div>
);
